<template>
    <div v-if="barChartProps">
        <BarChart v-bind="barChartProps" />
    </div>
</template>

<script>
import { computed } from 'vue'
import { Chart, registerables } from 'chart.js'
import { BarChart, useBarChart } from 'vue-chart-3'

Chart.register(...registerables)

export default {
    name: 'ChartBar',
    props: [ 'data', 'label' ],
    components: { BarChart },
    setup (props) {

        const chartData = computed(() => ({
            labels: props.label,
            datasets: props.data,
        }))

        const options = computed(() => ({
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawBorder: true,
                    },
                },
                y: {
                    grid: {
                        display: false,
                        drawBorder: true,
                    },
                }
            },
            plugins: {
                legend: {
                    position: "top",
                }
            }
        }))

        const { barChartProps, barChartRef } = useBarChart({
            chartData: chartData, 
            options
        })

        return { barChartProps, barChartRef }

    }
}
</script>

<style>

</style>